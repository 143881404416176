import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
dayjs.extend(utc);
dayjs.extend(timezone);

const WeightPerDayChart = ({ weightPerDay }) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (weightPerDay) {
      const dayLabels = weightPerDay.map((weightPerDayObj) =>
        dayjs(weightPerDayObj["date"]).format("ddd")
      );
      const dayWeights = weightPerDay.map(
        (weightPerDayObj) => weightPerDayObj.weight
      );

      const data = {
        labels: dayLabels,
        datasets: [
          {
            data: dayWeights,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            fill: false,
            tension: 0.1,
          },
        ],
      };

      setChartData(data);
    }
  }, [weightPerDay, theme.palette.primary.main]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          color: theme.palette.text.primary,
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Weight: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.primary,
          font: {
            size: 12,
          },
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      y: {
        ticks: {
          color: theme.palette.text.primary,
          font: {
            size: 12,
          },
        },
        grid: {
          color: theme.palette.divider,
        },
      },
    },
  };

  return (
    <Box sx={{ width: "100%", height: "144px", mt: 2 }}>
      {chartData ? (
        <Line options={options} data={chartData} />
      ) : (
        <Typography>No data available</Typography>
      )}
    </Box>
  );
};

export default WeightPerDayChart;
