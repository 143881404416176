import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormGroup,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import { validateEmail } from "../utils/account_verify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { login } from "../redux/slices/authSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function LoginRegisterModal({ open, handleOpen, handleClose }) {
  const theme = useTheme();
  const [isRegister, setIsRegister] = useState(true);
  const dispatch = useDispatch();
  const [showUserGuide, setShowUserGuide] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    try {
      dispatch(login({ email, password }));
    } catch (error) {
      setError("Invalid email or password.");
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      await axios.post(`/api/account/register`, {
        email: email,
        password,
      });
      setLoading(false);
      alert("Registration successful. Please log in.");
      setIsRegister(false);
    } catch (err) {
      const error = err.response.data.error;
      console.log("my err", error);
      setLoading(false);
      setError(error);
    }
  };

  const handleHideUserGuide = (isRegister) => {
    if (isRegister === null) {
      handleClose();
    }
    if (isRegister === false) {
      setShowUserGuide(false);
      setIsRegister(false);
    }
    if (isRegister === true) {
      setShowUserGuide(false);
      setIsRegister(true);
    }
  };

  const BackButton = () => {
    return (
      <Box
        style={{
          width: "100%",
          marginBottom: "24px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          variant="contained"
          color="secondary"
          onClick={() => setShowUserGuide(true)}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Keeps the backdrop
        },
        paddingTop: "22%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "244px",
          height: "80%",
          maxWidth: "300px", // Control modal width
          maxHeight: "400px", // Control modal height
          padding: "24px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
          overflow: "auto", // Enable scrolling for overflow content
        }}
      >
        {!showUserGuide ? <BackButton /> : null}
        {showUserGuide ? (
          <>
            <Typography
              variant="h4"
              sx={{ marginBottom: "20px", color: theme.palette.text.primary }}
            >
              Weightliftr
            </Typography>
            <Typography
              variant="body"
              sx={{ marginBottom: "20px", color: theme.palette.text.primary }}
            >
              In order to use Weightliftr features register or login.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                onClick={() => handleHideUserGuide(false)}
                sx={{ marginBottom: "10px", marginRight: "8px" }}
              >
                Login
              </Button>
              <Button
                variant="contained"
                onClick={() => handleHideUserGuide(true)}
                sx={{ marginBottom: "10px" }}
              >
                Register
              </Button>
            </Box>

            <Button
              variant="contained"
              onClick={() => handleHideUserGuide(null)}
            >
              Back
            </Button>
          </>
        ) : isRegister ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "300px",
              padding: "4px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography
              variant="h4"
              sx={{ marginBottom: "20px", color: theme.palette.text.primary }}
            >
              Register
            </Typography>
            {error && (
              <Typography
                variant="body2"
                color="error"
                sx={{ marginBottom: "20px" }}
              >
                {error}
              </Typography>
            )}
            <form onSubmit={handleRegister}>
              <FormGroup>
                <Box className="input-group">
                  <TextField
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    sx={{
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                        WebkitTextFillColor: theme.palette.text.primary,
                      },
                    }}
                  />
                </Box>
                <Box className="input-group">
                  <TextField
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    sx={{
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                        WebkitTextFillColor: theme.palette.text.primary,
                      },
                    }}
                  />
                </Box>
                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Register
                </Button>
              </FormGroup>
            </form>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "300px",
              padding: "4px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography
              variant="h4"
              sx={{ marginBottom: "20px", color: theme.palette.text.primary }}
            >
              Login
            </Typography>
            {error && (
              <Typography
                variant="body2"
                color="error"
                sx={{ marginBottom: "20px" }}
              >
                {error}
              </Typography>
            )}
            <form onSubmit={handleLogin}>
              <FormGroup>
                <Box className="input-group">
                  <TextField
                    type="email"
                    variant="outlined"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    color="secondary"
                    sx={{
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                        WebkitTextFillColor: theme.palette.text.primary,
                      },
                    }}
                  />
                </Box>
                <Box className="input-group">
                  <TextField
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    required
                    color="secondary"
                    sx={{
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                        WebkitTextFillColor: theme.palette.text.primary,
                      },
                    }}
                  />
                </Box>
                <Button
                  sx={{ marginTop: "16px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </FormGroup>
            </form>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
