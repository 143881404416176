import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Box, Card, Typography, IconButton } from "@mui/material";
import NavBar from "../../NavBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ExerciseStats = () => {
  const { exercise_name } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.authSlice);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const handleGetExerciseStats = async () => {
      const response = await axios.post(
        `/api/account/getExerciseStats`,
        {
          exercise_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const labels = response.data
        ? response.data.stats.map((stats) =>
            dayjs(stats.created_at).format("MMM D, YYYY")
          )
        : [];

      const weights = response.data
        ? response.data.stats.map((stats) => parseInt(stats.weight))
        : [];

      const data = {
        labels, // X-axis labels
        datasets: [
          {
            label: `${exercise_name} Set Weight`,
            data: weights, // Y-axis data
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            tension: 0.3, // For smooth curve
            pointBackgroundColor: theme.palette.primary.dark,
          },
        ],
      };

      setChartData(data);
    };

    handleGetExerciseStats();
  }, [token, exercise_name, theme.palette.primary]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.primary,
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      y: {
        ticks: {
          color: theme.palette.text.primary,
        },
        grid: {
          color: theme.palette.divider,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        className="main"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "scroll",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%", paddingBottom: "24px" }}>
          <NavBar />
        </Box>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          sx={{ width: "100%", height: "100%" }}
        >
          <Box sx={{ padding: "16px" }}>
            <Card
              style={{
                padding: "20px",
                borderRadius: "16px",
                width: "300px",
                height: "auto",
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <IconButton onClick={() => navigate("/")}>
                <ArrowBackIcon />
              </IconButton>
              <Box sx={{ paddingLeft: "16px", paddingTop: "16px" }}>
                <Typography variant="h4" sx={{ marginBottom: "8px" }}>
                  {exercise_name}
                </Typography>

                <Typography variant="body1">Set weight over time</Typography>
              </Box>

              <Box sx={{ padding: "16px", height: "300px" }}>
                {chartData ? (
                  <Line options={options} data={chartData} />
                ) : (
                  <Typography variant="body1">Loading chart...</Typography>
                )}
              </Box>
            </Card>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default ExerciseStats;
