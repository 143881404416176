import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "./App.css";
import { NavBar } from "./components/index.js";
import { useTheme } from "@mui/material/styles";
import Home from "./components/Account/Home.js";

const App = () => {
  const theme = useTheme();

  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (event) => {
      console.log("beforeInstallPromptEvent", event);
      // Prevent the default install prompt from showing
      event.preventDefault();

      // Save the event for triggering later
      setInstallPromptEvent(event);
      setIsInstallable(true); // Show the custom button
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  // Function to handle button click and trigger the prompt
  const handleInstallClick = () => {
    if (installPromptEvent) {
      installPromptEvent.prompt(); // Show the install prompt

      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }

        // Reset the installPromptEvent so it cannot be reused
        setInstallPromptEvent(null);
        setIsInstallable(false);
      });
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        className="main"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "scroll",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%", paddingBottom: "24px" }}>
          <NavBar
            handleInstallClick={handleInstallClick}
            isInstallable={isInstallable}
          />
        </Box>
        <Home />
      </Box>
    </Box>
  );
};

export default App;
