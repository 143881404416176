export const getRootDomain = () => {
  const { hostname } = window.location;
  const parts = hostname.split(".");

  if (hostname.includes("localhost")) {
    return "localhost:8788";
  }

  if (hostname.endsWith(".pages.dev")) {
    return parts.slice(parts.length - 4).join(".");
  }

  if (parts.length > 2) {
    return parts.slice(parts.length - 2).join(".");
  }

  return hostname;
};
