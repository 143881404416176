import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createWorkout } from "../../../redux/slices/workoutSlice";
import { useTheme } from "@mui/material/styles";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CreateIcon from "@mui/icons-material/Create";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

const SelectNewWorkoutType = ({ setWorkOutFlowState }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { workout_id, template_id } = useSelector(
    (state) => state.workoutSlice
  );
  const { workoutTemplates } = useSelector((state) => state.profileSlice);

  useEffect(() => {
    if (template_id) {
      navigate(`/workout-template/${template_id}`);
    }
  }, [template_id, navigate]);

  useEffect(() => {
    if (workout_id) {
      navigate(`/workout/${workout_id}`);
    }
  }, [workout_id, navigate]);

  const handleCreateBlankWorkout = () => {
    const is_template = false;
    dispatch(createWorkout({ token, is_template }));
  };

  const handleCreateWorkoutTemplate = () => {
    const is_template = true;
    dispatch(createWorkout({ token, is_template }));
  };

  const handleCreateWorkoutFromTemplate = () => {
    navigate("/workout-template-select");
    // setWorkOutFlowState("WorkoutTemplateSelect");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none", // For Chrome, Edge, and Safari
        },
      }} // Prevent default scrollbar
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "316px",
            flexDirection: "row",
            justifyContent: "flex-start",
            paddingTop: "16px",
          }}
        >
          <IconButton onClick={() => navigate("/")}>
            <ArrowBackIcon />
          </IconButton>
        </Box>

        <Box sx={{ padding: "24px" }}>
          <Card
            style={{
              padding: "20px",
              borderRadius: "16px",
              width: "300px",
              height: "auto",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <SummarizeIcon sx={{ paddingRight: "8px" }} />
              <Typography variant="h6">Create New Workout</Typography>
            </Box>

            <Box sx={{ paddingTop: "16px" }}>
              <List>
                <ListItem>
                  <ListItemButton onClick={handleCreateBlankWorkout}>
                    <ListItemIcon>
                      <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create Blank Workout" />
                  </ListItemButton>
                </ListItem>
                {workoutTemplates.length > 0 ? (
                  <ListItem>
                    <ListItemButton onClick={handleCreateWorkoutFromTemplate}>
                      <ListItemIcon>
                        <NoteAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Create From Template" />
                    </ListItemButton>
                  </ListItem>
                ) : null}

                <ListItem>
                  <ListItemButton onClick={handleCreateWorkoutTemplate}>
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create Template" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Card>
        </Box>
      </Box>
    </motion.div>
  );
};

export default SelectNewWorkoutType;
