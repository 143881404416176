import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Divider, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkoutPublic,
  createWorkoutCopy,
} from "../../redux/slices/workoutSlice";
import Exercise from "./Exercise";
import WorkoutMenu from "./WorkoutMenu";
import WorkoutTitle from "./WorkoutTitle";
import NavBar from "../NavBar";
import LoginRegisterModal from "../LoginRegisterModal";
import { useParams, useNavigate } from "react-router-dom";

const WorkoutPublic = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { pub_workout_id, user_id } = useParams();
  const { workout, workout_id } = useSelector((state) => state.workoutSlice);
  const [workoutActivities, setWorkoutActivities] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (pub_workout_id) {
      if (token) {
        console.log("token now exists");
        dispatch(
          createWorkoutCopy({
            token: token,
            workout_id: pub_workout_id,
            user_id: user_id,
          })
        );
      }
      if (!token && user_id) {
        dispatch(
          getWorkoutPublic({
            token: token,
            workout_id: pub_workout_id,
            user_id: user_id,
          })
        );
      }
    }
  }, [pub_workout_id, user_id, dispatch, token]);

  useEffect(() => {
    if (pub_workout_id && workout_id) {
      if (pub_workout_id !== workout_id) {
        navigate(`/workout/${workout_id}`);
      }
    }
  }, [pub_workout_id, workout_id, navigate]);

  useEffect(() => {
    if (workout) {
      const allActivitiesReset = workout.allWorkoutActivities.map(
        (activity) => {
          return {
            ...activity,
            completed: false,
          };
        }
      );
      setWorkoutActivities(allActivitiesReset);
    }
  }, [workout]);

  const handleUserActionWithNoToken = () => {
    handleOpen();
  };

  const handleCheckBoxChange = async (activity, completed, index) => {
    let copy = [...workoutActivities];
    copy[index] = { ...copy[index], completed };
    setWorkoutActivities(copy);
  };

  const handleCopyWorkout = () => {
    const parsedStrings = workoutActivities.reduce((workout, activity) => {
      return `${workout}${activity.parsed}\n`;
    }, "");
    navigator.clipboard
      .writeText(parsedStrings)
      .then(() => {
        console.log("Workout copied to clipboard!");
        alert("Workout copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "scroll",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%", paddingBottom: "24px" }}>
          <NavBar isMenuDisabled={true} isInstallable={false} />
        </Box>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Box>
            <Card
              sx={{
                height: "90%",
                width: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "24px",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "10px",
              }}
            >
              <LoginRegisterModal
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
              {workout ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "16px",
                  }}
                >
                  <Box>
                    <WorkoutTitle />
                  </Box>
                  <WorkoutMenu
                    handleCopyWorkout={handleCopyWorkout}
                    handleDeleteWorkout={handleUserActionWithNoToken}
                    handleFinishWorkout={handleUserActionWithNoToken}
                    handleCreateTemplateFromWorkout={
                      handleUserActionWithNoToken
                    }
                    handleShareWorkout={handleUserActionWithNoToken}
                  />
                </Box>
              ) : null}

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {workoutActivities.map((activity, index) => {
                    return (
                      <Box
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          paddingBottom: "4px",
                          paddingTop: "4px",
                        }}
                      >
                        <Typography key={index} variant="body1">
                          {activity.parsed}
                        </Typography>
                        <Checkbox
                          onChange={(event) =>
                            handleCheckBoxChange(
                              activity,
                              event.target.checked,
                              index
                            )
                          }
                          checked={activity.completed}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <Box sx={{ paddingTop: "8px", paddingBottom: "16px" }}>
                  <Divider />
                </Box>
                <Box>
                  <Exercise
                    addRow={handleUserActionWithNoToken}
                    addExercise={handleUserActionWithNoToken}
                    addRun={handleUserActionWithNoToken}
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default WorkoutPublic;
