import React, { useState, useEffect } from "react";
import { Box, Card, IconButton, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWorkoutTemplate,
  getWorkout,
  clearCurrentTemplate,
  createWorkoutFromTemplate,
  setCurrentWorkout,
} from "../../redux/slices/workoutSlice";
import { getProfile } from "../../redux/slices/profileSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Exercise from "../Workout/Exercise";
import axios from "axios";
import WorkoutTitle from "../Workout/WorkoutTitle";
import NavBar from "../NavBar";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useParams, useNavigate } from "react-router-dom";

const WorkoutTemplate = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const { workout_id } = useParams();
  const { workout } = useSelector((state) => state.workoutSlice);
  console.log("workout", workout);
  const [workoutActivities, setWorkoutActivities] = useState([]);

  useEffect(() => {
    if (workout_id) {
      dispatch(getWorkout({ token, workout_id }));
    }
  }, [workout_id, dispatch, token]);

  useEffect(() => {
    if (workout) {
      setWorkoutActivities(workout.allWorkoutActivities);
    }
  }, [workout]);

  const handleDeleteWorkout = async () => {
    try {
      await dispatch(deleteWorkoutTemplate({ token, workout_id }));
      navigate("/");
    } catch (error) {
      console.error("Failed to delete workout:", error);
    }
  };

  const handleFinishTemplate = () => {
    try {
      dispatch(clearCurrentTemplate());
      dispatch(getProfile({ token }));
      navigate("/");
    } catch (error) {
      console.error("Failed to finish workout:", error);
    }
  };

  const handleAddExerciseToWorkout = async (exercise) => {
    const result = await axios.post(
      `/api/exercise/createExercise`,
      {
        workout_id,
        exercise,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
    const savedExercise = {
      parsed: `${exercise.reps} X ${exercise.sets} ${exercise.name} @ ${
        exercise.weight
      } ${exercise.exerciseUnit === 0 ? "lbs" : "kgs"}`,
    };
    console.log("saved", savedExercise, workoutActivities);
    setWorkoutActivities([...workoutActivities, savedExercise]);
  };

  const handleAddRowToWorkout = async (row) => {
    console.log("row", row);
    const result = await axios.post(
      `/api/exercise/createRow`,
      {
        workout_id,
        row,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
    const savedRow = { parsed: `${row.rowDistance}m row in ${row.rowTime}` };
    setWorkoutActivities([...workoutActivities, savedRow]);
  };

  const handleAddRunToWorkout = async (run) => {
    console.log("run", run);
    const result = await axios.post(
      `/api/exercise/createRun`,
      {
        workout_id,
        run,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(result);
    const savedRun = {
      parsed: `${run.runDistance} meter run in ${run.runTime}`,
    };
    setWorkoutActivities([...workoutActivities, savedRun]);
  };

  const handleCreateWorkoutFromTemplate = () => {
    dispatch(
      createWorkoutFromTemplate({
        token: token,
        template_id: workout_id,
        config: "default",
      })
    );
    handleShowWorkout(workout_id);
  };

  const handleShowWorkout = (workout_id) => {
    dispatch(clearCurrentTemplate());
    dispatch(setCurrentWorkout(workout_id));
    navigate(`/workout/${workout_id}`);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "scroll",
          background: theme.palette.background.default,
        }}
      >
        <Box style={{ width: "100%", paddingBottom: "24px" }}>
          <NavBar />
        </Box>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Box>
            <Card
              sx={{
                height: "90%",
                width: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "24px",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "10px",
              }}
            >
              {workout ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "16px",
                  }}
                >
                  <Box>
                    <WorkoutTitle />
                  </Box>
                  <Box>
                    <IconButton onClick={handleCreateWorkoutFromTemplate}>
                      <PlayCircleFilledWhiteIcon />
                    </IconButton>
                    <IconButton onClick={handleFinishTemplate}>
                      <SaveIcon color="text.secondary" />
                    </IconButton>
                    <IconButton
                      color="text.secondary"
                      onClick={handleDeleteWorkout}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ) : null}

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {workoutActivities.map((activity, index) => {
                    return (
                      <Box
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Typography key={index} variant="body1">
                          {activity.parsed}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Box sx={{ paddingTop: "8px", paddingBottom: "16px" }}>
                  <Divider />
                </Box>
                <Box>
                  <Exercise
                    addRow={handleAddRowToWorkout}
                    addExercise={handleAddExerciseToWorkout}
                    addRun={handleAddRunToWorkout}
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default WorkoutTemplate;
