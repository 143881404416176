import {
  Box,
  Typography,
  Card,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createWorkoutFromTemplate,
  deleteWorkout,
} from "../../../redux/slices/workoutSlice";
import { getProfile } from "../../../redux/slices/profileSlice";
import { useTheme } from "@mui/material/styles";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import DeleteIcon from "@mui/icons-material/Delete";

const SelectWorkoutTemplate = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.authSlice);
  const { workoutTemplates } = useSelector((state) => state.profileSlice);
  const { workout_id } = useSelector((state) => state.workoutSlice);
  const [showTemplateConfig, setShowTemplateConfig] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplateHasChildren, setSelectedTemplateHasChildren] =
    useState(null);

  useEffect(() => {
    if (workout_id) {
      navigate(`/workout/${workout_id}`);
    }
  }, [workout_id, navigate]);

  useEffect(() => {
    if (workoutTemplates.length === 0) {
      navigate(`/workout-type-select`);
    }
  }, [workoutTemplates, navigate]);

  const handleCreateWorkoutFromTemplate = (config) => {
    dispatch(
      createWorkoutFromTemplate({
        token: token,
        template_id: selectedTemplateId,
        config,
      })
    );
  };

  const handleShowTemplateConfig = (
    template_id,
    template_name,
    has_children
  ) => {
    setShowTemplateConfig(true);
    setSelectedTemplateId(template_id);
    setSelectedTemplateHasChildren(has_children);
  };

  const handleDeleteWorkout = async () => {
    try {
      await dispatch(deleteWorkout({ token, workout_id: workoutToDelete }));
      dispatch(getProfile({ token: token }));
      setWorkoutToDelete(null);
      setOpen(false);
    } catch (error) {
      console.error("Failed to delete workout:", error);
    }
  };

  const [open, setOpen] = useState(false);
  const [workoutToDelete, setWorkoutToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDeleteButton, setShowDeleteButton] = React.useState(false);
  const isEditMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setShowDeleteButton(!showDeleteButton);
  };

  const handleOpenDeleteDialog = (workout_id) => {
    setWorkoutToDelete(workout_id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setWorkoutToDelete(null);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none", // For Chrome, Edge, and Safari
        },
      }} // Prevent default scrollbar
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "316px",
            flexDirection: "row",
            justifyContent: "flex-start",
            paddingTop: "16px",
          }}
        >
          <IconButton onClick={() => navigate("/workout-type-select")}>
            <ArrowBackIcon />
          </IconButton>
        </Box>

        <Box sx={{ padding: "24px" }}>
          <Card
            style={{
              padding: "20px",
              borderRadius: "16px",
              width: "300px",
              height: "auto",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoteAddIcon sx={{ paddingRight: "8px" }} />
                <Typography variant="h6">
                  {!showTemplateConfig
                    ? "Select Template"
                    : "Choose Workout Weights"}
                </Typography>
              </Box>
              {!showTemplateConfig ? (
                <Box>
                  <IconButton
                    size="small"
                    id="basic-button"
                    aria-controls={isEditMenuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={isEditMenuOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={isEditMenuOpen}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  </Menu>
                </Box>
              ) : null}
            </Box>

            {!showTemplateConfig ? (
              <Box sx={{ paddingTop: "16px" }}>
                <List>
                  {workoutTemplates.map((template) => {
                    return (
                      <ListItem>
                        <ListItemButton
                          onClick={() =>
                            handleShowTemplateConfig(
                              template.workout_id,
                              template.workout_text,
                              template.has_children
                            )
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText primary={template.workout_text} />
                        </ListItemButton>
                        {showDeleteButton ? (
                          <IconButton>
                            <DeleteIcon
                              onClick={() =>
                                handleOpenDeleteDialog(template.workout_id)
                              }
                            />
                          </IconButton>
                        ) : null}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            ) : (
              <Box sx={{ paddingTop: "16px" }}>
                <List>
                  <ListItem>
                    <ListItemButton
                      onClick={() => handleCreateWorkoutFromTemplate("default")}
                    >
                      <ListItemIcon>
                        <PlayCircleFilledWhiteIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Default template weights"} />
                    </ListItemButton>
                  </ListItem>
                  {selectedTemplateHasChildren ? (
                    <Box>
                      <ListItem>
                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("most_recent")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Use last workout weight"} />
                        </ListItemButton>
                      </ListItem>

                      <ListItem>
                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("five_percent")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Increase last workout weight by 5%"}
                          />
                        </ListItemButton>
                      </ListItem>

                      <ListItem>
                        <ListItemButton
                          onClick={() =>
                            handleCreateWorkoutFromTemplate("ten_percent")
                          }
                        >
                          <ListItemIcon>
                            <PlayCircleFilledWhiteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Increase last workout weight by 10%"}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Box>
                  ) : null}
                </List>
              </Box>
            )}
            <Dialog
              sx={{ borderRadius: "16px" }}
              open={open}
              onClose={handleCloseDialog}
            >
              <DialogTitle>Delete Workout Template</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this workout template? This
                  action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={handleCloseDialog}>
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleDeleteWorkout}
                  color="error"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Box>
      </Box>
    </motion.div>
  );
};

export default SelectWorkoutTemplate;
