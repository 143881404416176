import React from "react";
import { Button, Box } from "@mui/material";
import { motion } from "framer-motion";
import Loading from "../Loading";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WeeklyStats from "./WeeklyStats";
import RecentWorkout from "./RecentWorkout";
import ExerciseList from "./ExerciseList";

const Home = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.authSlice);

  const handleStartWorkoutFlow = async () => {
    navigate("/workout-type-select");
  };

  if (!token) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Loading />
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none", // For Chrome, Edge, and Safari
        },
      }} // Prevent default scrollbar
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleStartWorkoutFlow}
            variant="contained"
            size="large"
            sx={{ maxWidth: "150px", height: "64px" }}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WeeklyStats />
          <RecentWorkout />
          <ExerciseList />
        </Box>
      </Box>
    </motion.div>
  );
};

export default Home;
