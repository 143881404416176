import { Box, Typography, Card, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const ExerciseList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { token } = useSelector((state) => state.authSlice);
  const [exercises, setExercises] = useState(null);

  useEffect(() => {
    const handleListExercises = async () => {
      const response = await axios.get(`/api/account/listExercises`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setExercises(
        response.data.exercises.length > 0 ? response.data.exercises : null
      );
    };

    handleListExercises();
  }, [token]);

  if (exercises === null) {
    return null;
  }

  console.log("exercise state", exercises);

  return (
    <Box sx={{ padding: "24px" }}>
      <Card
        style={{
          padding: "20px",
          borderRadius: "16px",
          width: "300px",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ paddingBottom: "16px" }} variant="h6">
            Your Exercises
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {exercises.map((exercise) => {
            return (
              <Button
                variant="outlined"
                onClick={() => navigate(`/stats/${exercise}`)}
                sx={{
                  textTransform: "none",
                  marginBottom: "8px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "8px",
                  borderRadius: "8px",
                }}
              >
                <Typography sx={{ color: theme.palette.text.primary }}>
                  {exercise}
                </Typography>
                <ArrowCircleRightIcon />
              </Button>
            );
          })}
        </Box>
      </Card>
    </Box>
  );
};

export default ExerciseList;
